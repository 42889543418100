<template>
  <b-card v-if="isMain">
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t('backoffice.settings.support-chat') }}</h4>
      <div class="d-flex">
        <b-button variant="primary" class="ml-1" @click="saveChat()">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </div>
    <b-row class="d-flex flex-wrap">
        <b-col cols="6">
          <!-- Select chatbot -->
          <b-form-group class="flex-grow-1">
            <label for="show" class="font-weight-bold">{{$t('form.app-label')}}</label>
            <v-select
              id="show"
              v-model="form.show"
              :placeholder="$t('form.select-placeholder')"
              :options="chatOptions"
              :searchable="false"
              label="name"
              :clearable="false"
              @input="showChatbot()"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <!-- Custom chatbot ID -->
          <b-form-group v-if="form.show !== $t('backoffice.settings.chatbot-disabled')" class="flex-grow-1">
            <label for="script" class="font-weight-bold">{{$t('backoffice.settings.id-label')}}</label>
            <b-form-input
              id="script"
              v-model="form.customChat"
              name=" "
              :placeholder="$t('backoffice.settings.insert-chat-id-placeholder')"
              rows="8"
              rules="required"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="small" v-if="form.show !== $t('backoffice.settings.chatbot-disabled')">
        <span class="font-italic">{{ $t('backoffice.settings.chatbot-note') }}</span>
        <br>
        <span class="font-italic"><a :href="chatbotAppLink" target="_blank">{{ chatbotAppLink }}</a></span>
      </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BButton, BFormTextarea,
} from 'bootstrap-vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

export default {
  name: 'ChatBot',
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        show: this.$t('backoffice.settings.chatbot-disabled'),
        customChat: null,
      },
      chatShow: null,
      chatShow: null,
    };
  },
  computed: {
    isMain() {
      return this.$store.getters.currentCollective?.communityMain == null || this.$store.getters.currentCollective?.communityMain === this.$store.getters.currentCollective?.key;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    chatOptions() {
      return [
        this.$t('backoffice.settings.chatbot-disabled'),
        this.$t('backoffice.settings.crips'),
        this.$t('backoffice.settings.hubspot'),
        this.$t('backoffice.settings.chatBot'),
        this.$t('backoffice.settings.landbot'),
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    chatbotAppLink() {
      if(this.form.show === 'Crisp'){
        return 'https://crisp.chat/';
      }else if(this.form.show === 'Hubspot'){
        return 'https://www.hubspot.com/products/crm/chatbot-builder';
      }else if(this.form.show === 'ChatBot'){
        return 'https://www.chatbot.com/';
      }else if(this.form.show === 'Landbot'){
        return 'https://landbot.io/';
      }else{
        return null;
      }
    },
  },
  created() {
    const chatbotType = this.currentCollective?.showChatbot;
    if (chatbotType <= 1) {
      //Nectios chat
      this.form.show = this.$t('backoffice.settings.chatbot-disabled');
    }else{
      if (chatbotType === 2) {
        this.form.show = 'Crisp';
      }else if(chatbotType === 3){
        this.form.show = 'Hubspot';
      }else if(chatbotType === 4){
        this.form.show = 'ChatBot';
      }else if(chatbotType === 5){
        this.form.show = 'Landbot';
      }
      this.form.customChat = this.currentCollective?.customChatbot;
    }
  },
  methods: {
    showChatbot() {
      if(this.form.show === 'Crisp'){
        return this.chatShow = 2;
      }else if(this.form.show === 'Hubspot'){
        return this.chatShow = 3;
      }else if(this.form.show === 'ChatBot'){
        return this.chatShow = 4;
      }else if(this.form.show === 'Landbot'){
        return this.chatShow = 5;
      }else{
        return this.chatShow = 0;
      }
    },
    async saveChat() {
      try {
          //Save chatbot data
          await this.$store.dispatch('addSettings', { 
            showChatbot: this.chatShow, 
            customChatbot: this.form.customChat 
          });
          this.notifySuccess(this.$t('backoffice.settings.messages.success'));
        } catch {
          this.notifyError(this.$t('backoffice.settings.messages.error'));
        }
        const chatForm = {
          chatShow: this.chatShow,
          customChat: this.form.customChat,
        };
        this.$emit('chat', chatForm);
    },
  },
};
</script>
